<template>
  <div>
    <div>
      <k-header-section
        header-text="Shipments"
        item-label="Shipments"
        :sub-nav-bar-items="navDatas"
        :total-items-count="totalItemsCount"
        :current-page-count="currentPageCount"
      />
    </div>
    <div>
      <shipment-list
        @update-total-items="updateTotalItems"
      />
    </div>
  </div>
</template>

<script>
import { KHeaderSection } from '@kingpin-global/kingpin-ui'
import shipmentsNavBarContent from '@/assets/app-content/shipments-navbar-content.json'
import ShipmentList from './ShipmentList.vue'

export default {
  name: 'Shipments',
  components: {
    KHeaderSection,
    ShipmentList,
  },
  data() {
    return {
      navDatas: shipmentsNavBarContent.navBarItems,
      currentPageCount: 0,
      totalItemsCount: 0,
    }
  },
  methods: {
    updateTotalItems(currentPageCount, totalItemsCount) {
      this.currentPageCount = currentPageCount
      this.totalItemsCount = totalItemsCount
    },
  },
}
</script>
