<template>
  <div class="table-padding">
    <div class="filter-sorting-container">
      <k-search-bar-section
        placeholder="Search By Brand, Retailer, Order..."
        hide-sort-option
        hide-filter-option
        hide-column-edit-option
        @on-search="updateSearch"
      />
      <multiple-filter
        class="ms-2"
        :filter-fields="filterFields"
        @on-apply-filter="onApplyFilter"
      />
      <k-sorting
        class="ms-auto"
        hide-column-edit-option
        :demo-checkbox-label="`${ showDemoData ? 'Hide':'Show'} Demo Shipments`"
        :self-managed-checkbox-label="`${ showSelfManagedData ? 'Hide':'Show'} Direct Shipments`"
        show-demo-checkbox
        show-self-managed-checkbox
        :sort-items="sortFields"
        @update-sort="updateSort"
        @update-sort-direction="updateSortDirection"
        @update-demo-filter="updateDemoFilter"
        @update-self-managed-filter="updateSelfManagedFilter"
      />
    </div>
    <div
      v-if="loading"
      class="spinner-body"
    >
      <b-spinner />
    </div>
    <div
      v-else
      class="table-responsive"
    >
      <!-- Table section -->
      <p-table
        :fields="fields"
        :items="items"
      >
        <template #empty>
          <p class="h2 d-flex table-empty-message justify-content-center align-items-center gap-2">
            <b-icon
              icon="exclamation-circle"
              scale="1"
            />
            <span> No Filtered Items </span>
          </p>
        </template>
        <template #cell(shipmentSeqId)="data">
          <b-link
            class="text-decoration-none text-nowrap"
            :to="{path : `/shipment-management/${data.item.shipmentObjId}/shipment-products-tab`}"
          >
            {{ data.item.shipmentSeqId || data.item.shipmentObjId }}
          </b-link>
        </template>
        <template #cell(orderSeqId)="data">
          <b-link
            class="text-decoration-none text-nowrap"
            :to="{ path : `/order-details/${data.item.orderId}` }"
          >
            {{ (data.item.orderSeqId || data.item.orderId) }}
          </b-link>
        </template>
        <template #cell(paymentTerms)="data">
          <span class="text-nowrap">
            {{ data.item.paymentTerms }}
          </span>
        </template>
        <template #cell(createdAt)="data">
          {{ formattedDate(data.item.createdAt) }}
        </template>
        <template #cell(deliveredDate)="data">
          <span v-if="data.item.deliveredDate">
            {{ formattedDate(data.item.deliveredDate) }}
          </span>
        </template>
        <template #cell(totalPrice)="data">
          <span class="text-nowrap">
            {{ formatCurrency((data.item.totalPriceAfterDiscount || data.item.totalPrice), data.item.currency) }}
            <span
              v-if="data.item.discountInCredit"
              v-b-tooltip.hover.bottom="`${data.item.discountInCredit} Credit Points were applied to this shipment`"
              class="credit-point-tooltip"
            >
              CP
            </span>
          </span>
        </template>
      </p-table>
    </div>
    <k-table-pagination
      :total-items="itemsCount"
      @emit-current-page="updateCurrentPage"
      @emit-per-page="updatePerPage"
    />
  </div>
</template>

<script>
import { BIcon, VBTooltip, BLink } from 'bootstrap-vue'
import { PTable, KTablePagination, KSearchBarSection } from '@kingpin-global/kingpin-ui'
import { PTablePaginationMixin } from '@/mixins/p-table.mixin'
import { KSearchAndSortMixin } from '@/mixins/k-searbar-section.mixin'
import { mapState, mapGetters } from 'vuex'
import { FETCH_SHIPMENT_LIST, SET_PAYLOAD, SET_SHIPMENTS } from '@/store/modules/shipment.module'
import { formattedDate, capitalize } from '@/@core/utils/format'
import { apiToastError } from '@/@core/utils/toast'
import { utils } from '@kingpin-global/kingpin-utils-frontend'
import { getShipmentFields } from '@/table-fields-constants'

const { formatCurrency } = utils

export default {
  name: 'ShipmentList',
  components: {
    PTable,
    KTablePagination,
    KSearchBarSection,
    BIcon,
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [KSearchAndSortMixin, PTablePaginationMixin],
  data() {
    return {
      isDesc: false,
      fields: getShipmentFields(),
      formattedDate,
      loaded: false,
      formatCurrency,
      showDemoData: false,
      showSelfManagedData: false,
    }
  },
  computed: {
    ...mapState({
      shipments: (state => (state.shipments.shipmentList ? state.shipments.shipmentList : [])),
    }),
    ...mapGetters([
      'getRetailerName',
      'getBrandName',
    ]),
    items() {
      // Updating field data to db name Fields to perform backend sort
      return this.shipments.map(item => ({
        shipmentObjId: item._id,
        shipmentSeqId: item.shipmentSeqId || item._id,
        orderId: item.orderId,
        orderSeqId: item.orderSeqId || item.orderId,
        createdAt: item.createdAt,
        deliveredDate: item.deliveredDate,
        brandName: item.brandName,
        retailerName: item.retailerName,
        collectionName: item.collectionName,
        status: capitalize(item.status),
        totalUnits: item.totalQuantity,
        logisticsTerms: item.logisticsTerms,
        totalPrice: item.totalPrice,
        paymentTerms: item.paymentTerms,
        discountInCredit: item.discountInCredit || 0,
        totalPriceAfterDiscount: item.totalPriceAfterDiscount || 0,
        currency: item.currency || '',
      }))
    },
    sortFields() {
      return this.fields.filter(field => !['totalPrice', 'totalUnits', 'logisticsTerms', 'paymentTerms', 'orderSeqId'].includes(field.key))
    },
    loading() {
      return !(this.items.length || this.loaded)
    },
  },
  async created() {
    await this.initPageContent()
  },
  destroyed() {
    this.$store.commit(SET_SHIPMENTS, [])
  },
  methods: {
    async initPageContent() {
      this.loaded = false
      const queryParams = {
        page: this.currentPage - 1,
        limit: this.perPage,
        search: this.searchText,
        isDemo: this.showDemoData,
        isSelfManaged: this.showSelfManagedData,
      }
      if (this.sortBy) {
        queryParams.sortBy = this.sortBy
        queryParams.asc = !this.isDesc
      }
      if (Object.values(this.filterQuery).length) {
        queryParams.filter = JSON.stringify(this.filterQuery)
      }
      this.$store.commit(SET_PAYLOAD, queryParams)
      this.$store.dispatch(FETCH_SHIPMENT_LIST, { queryParams }).then(res => {
        this.setFilterFields(res.data.filter, this.fields)
        this.itemsCount = this.$store.getters.shipmentCount
        this.updateFilteredItemAndPaginationParams(this.items)
        this.loaded = true
      })
        .catch(err => {
          apiToastError(err)
        })
    },
  },
}
</script>
